<template>
  <div class="container">
    <div class="text-right my-4">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
      <template>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small class="mx-2" color="primary" dark v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('add')">
              Add
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add Module Service</span>
            </v-card-title>
            <v-form
              ref="form"
              @submit="addServiceData"
              method="post"
              v-model="valid"
              lazy-validation
            >
              <v-container class="px-50 v-card-padding" fluid>
                <v-text-field
                  v-model="addService.serviceName"
                  label="Service Name"
                  :rules="nameRules"
                  required
                ></v-text-field>
                <div style="color: red">
                  {{ message1 }}
                </div>
              </v-container>
              <v-container class="px-50" fluid> </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" type="submit" @click="validate">
                  Submit
                </v-btn>
                <v-btn class="close_button mx-2" text @click="reset"> Close </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>
      <v-dialog max-width="600" v-model="dialog2">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="error" small v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('delete')">Delete</v-btn>
        </template>
        <div v-if="deleteItems.length > 0">
          <template>
            <v-card>
              <v-card-text>
                <div class="text-h3 pa-12">
                  Are you sure you want to delete all the selected Record(s)?
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-form ref="form" method="post">
                  <v-btn text class="confirm_button mx-2" text v-on:click="dialog2 = false" @click="deleteService"
                    >Yes</v-btn
                  >
                </v-form>
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
        <div v-if="deleteItems.length == 0">
          <template>
            <v-card>
              <v-card-text class="center">
                <div class="text-h3 pa-12">
                  Please Select Record(s) to delete
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn class="close_button" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
      </v-dialog>
    </div>
    <div class="text-right"></div>
    <!-- v-data-table  -->
    <v-row>
      <v-col cols="12">
          <div class="tableWrapper">
            <div id="select-all-checkbox">
              <v-checkbox
                ref="n"
                v-model="allSelected"
                @click="selectAll"
              ></v-checkbox>
            </div>
            <v-data-table
              :headers="headers"
              item-key="product_id"
              :items="service_data"
              :options.sync="options"
              :server-items-length="total"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
            >
              <template v-slot:item.selectCheckbox="{ item }">
                <v-checkbox
                  ref="n"
                  v-model="deleteItems"
                  :value="item.service_id"
                   :key="item.service_id"
                  @click="addToDelete($event, item.service_id)"
                ></v-checkbox>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                      medium
                      color="grey"
                      @click="addToUpdate($event, item.service_id)"
                      v-show="checkIfOperationExistForModule('edit')"
                    >
                      mdi-pencil
                    </v-icon>
              </template>
            </v-data-table>
                <div class="text-center pt-4 pb-4">
              <Pagination
            :isloading="isLoading"
            :startRecord="startRecord"
            :currentPage="currentPage"
            :lastPage="lastPage"
            :lastRecord="lastRecord"
            :totRecords="totRecords"
            :isCurrentPageClass="isCurrentPageClass"
            :perpage="perpage"
            :getLastPageClass="getLastPageClass"
            :totPage="totPage"
            :getPages="getPages"
            @handlePerPage="handlePerPage"
            @paginate="paginate"
            @last="last"
            @getDataByPage="getDataByPage"
             />
            </div>
          </div>
      </v-col>
    </v-row>
    <v-row justify="center">
     <v-dialog
      v-model="toggleUpdateModal"
      max-width="600"
    >
      <template>
	  <v-card>
        <v-card-title>
          <span class="headline">Update Module Service</span>
        </v-card-title>
        <v-form
          ref="form"
          @submit="updateServiceData"
          method="put"
        >
          <v-container class="px-50" fluid>
            <v-text-field
              v-model="updateService.serviceName"
              label="Service Name"
              required
              :rules="nameRules"
            ></v-text-field>
            <div style="color: red">
              {{ upmessage1 }}
            </div>
          </v-container>
          <v-container class="px-50" fluid> </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              @click="updateServiceData"
              type="submit"
            >
              Update
            </v-btn>
            <v-btn class="close_button" text @click="toggleUpdateModal = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
	  </template>
     </v-dialog>
</v-row>
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import Pagination from "./component/Pagination.vue";

export default {
  components: { Index, Buttons, Loading, Pagination },
  data() {
    return {
      toggleUpdateModal:false,
      //////table data starts here////
      selected: [],
      options: {},
      sort: "",
      total: 0,
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        {
          text: "",
          value: "selectCheckbox",
          width: "50px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Service Name",
          value: "service_name",
          width: "200px",
          sortable: false,
          class: "v-data-table-header",
        },
         {
          text: "Service Repo Name",
          value: "service_repo_name",
          width: "200px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Action",
          value: "actions",
          width: "150px",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
      valid: true,
      checkbox: false,
      serviceName: [],
      service_data: [],
      isLoading: true,
      fullPage: true,
      dialog: false,
      dialog1: false,
      dialog2: false,
      deleteItems: [],
      checked_operationId: [],
      checkcedstr: "",
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      allSelected: false,
      addService: {
        serviceName: null,
      },
      updateService: {
        serviceId: null,
        serviceName: null,
      },
      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
      addServicelabel: null,
      //pagination code
      perpage: 50,
      totRecords: null,
      page: 1,
      isLoading: true,
      fullPage: true,
      ///
    };
  },
 computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    ///////
  },
  methods: {
    //pagination methods
    checkIfOperationExistForModule(type){
        return this.$utils.checkIfOperationExistForModule(this, type)
    },
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getServices(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getServices(this.page);
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getServices(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getServices(this.page);
    },
    /////
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.dialog = false;
      this.dialog1 = false;
      this.message1 = "";
      this.upmessage1 = "";
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    getServices(page = "") {
      const path = this.$url("MANAGE_SERVICES")+  "?page=" + page;
      this.isLoading = true;
      let check = this;
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check })
        .get(path)
        .then((res) => {
          this.isLoading = false;
          console.log(res);
          this.serviceName = res.data.payload.operation[0];
          this.total = this.serviceName.length;
          this.totRecords = res.data.payload.operation[2];
          this.pageNo = res.data.payload.operation[3];
          if (Array.isArray(this.serviceName) && this.serviceName.length) {
            this.service_data = [];
            let i;
            for (i = 0; i < this.serviceName.length; i++) {
              let tempobj = {
                service_id: this.serviceName[i].service_id,
                service_name: this.serviceName[i].name,
                service_repo_name: this.serviceName[i].repo_name,
              };
              this.service_data.push(tempobj);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addServiceData(e) {
      e.preventDefault();
      let check = this;
      console.log(this.addService.serviceName);
      if (
        this.addService.serviceName != "" &&
        this.addService.serviceName != null
      ) {
        this.$fetch({ requiresAuth: true, operation : 'add' , vueScope: check  })
          .post(this.$url("MANAGE_SERVICES"), this.addService)
          .then((result) => {
            console.log(result.data.payload);
            this.message1 = result.data.payload[0].serviceId;
            this.message2 = result.data.payload[0].serviceName;
            if (this.message2 == null) {
              this.message1 = result.data.payload[0].serviceId;
              this.dialog = true;
            } else {
              this.message1 = null;
              this.dialog = false;
              if (result.data.statusCode == 200 && this.message1 == null) {
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) added successfully",
                  text: result.data.payload.message,
                });
              }
              this.getServices();
              this.reset();
            }
            console.log(result);
          });
      }
    },
    addToUpdate(e, atchid) {
      let view = this;
      this.toggleUpdateModal = true
      view.serviceName.forEach(function (o, i) {
        if (o.service_id == atchid) {
          view.updateService.serviceName = o.name;
          view.updateService.serviceId = o.service_id;
        }
      });
    },
    updateServiceData(e) {
      e.preventDefault();
      let data = this;
      if (
        data.updateService.serviceName != "" &&
        data.updateService.serviceId != null
      ) {
        this.$fetch({ requiresAuth: true, operation: 'edit', vueScope: data  })
          .put(data.$url("MANAGE_SERVICES"), data.updateService)
          .then((result) => {
            this.upmessage1 = result.data.payload.serviceId;
            this.upmessage2 = result.data.payload.serviceName;
            if (this.upmessage2 == null) {
              this.upmessage1 = result.data.payload.serviceId;
              this.dialog1 = true;
            } else {
              this.upmessage1 = null;
              this.dialog1 = false;
              if (result.data.statusCode == 200 && this.upmessage1 == null) {
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) updated successfully",
                  text: result.data.payload.message,
                });
                this.toggleUpdateModal = false
              }
              this.getServices();
              this.reset();
            }

            console.log(result);
          });
      }
    },
    addToDelete(e, atchid) {
      this.checkedthis = false;
      //e.target.checked
      if (e.target.checked) {
        if (this.checked_operationId.indexOf(e.target.value) == -1) {
          this.checked_operationId.push(e.target.value);
        }
      } else {
        this.checked_operationId.splice(
          this.checked_operationId.indexOf(e.target.value),
          1
        );
      }
      let checkcedstr = this.checked_operationId.join(",");
      this.checkcedstr = checkcedstr;
      console.log(this.checkcedstr);
    },
    deleteService(e) {
      e.preventDefault();
      let check = this;
      console.log(this.deleteItems);
      this.$fetch({ requiresAuth: true, operation : 'delete', vueScope: check })
        .delete(this.$url("MANAGE_SERVICES"), {
          params: { id: this.deleteItems },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.$swal.fire({
              icon: "success",
              title: "Selected record(s) has been deleted",
              text: response.data.payload.message,
            });
          }
          this.getServices();
          this.deleteItems = [];
        });
    },
    selectAll: function () {
      console.log("y");
      let service = this.serviceName;
      if (this.allSelected == true) {
        if (Array.isArray(service) && service.length) {
          this.deleteItems = [];
          let i;
          for (i = 0; i < service.length; i++) {
            console.log(service[i]);
            this.deleteItems.push(service[i][0]);
          }
        }
      } else if (this.allSelected == false) {
        this.deleteItems = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
  },
  watch: {
 '$store.state.Rawdata.current_active_module': function(o) {
 if(o){
 this.getServices(1);
 }
 },
 page: function (ob) {
 this.getServices(ob);
 },
 },
};
</script>
